@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body{
    font-family: 'Inter', sans-serif;
}
.fa-solid.fa-heart{
    color: #CF0000;
}

input[type="file"]{
    display: none;
}
label.upload{
    color: white;
    border-radius: 4px;
    height: 100px;
    margin: auto;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
label.upload:hover{
    cursor: pointer;
}

/*  scroll css section here*/
button.scroll{
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* bottom: 50px; */
    /* right: 50px; */
    cursor: pointer;
    z-index: 99;
}

button.scroll:focus {
    outline: none;
}